import AOS from "aos";

jQuery(function () {
    AOS.init({
      startEvent: "load",
      once: true,
      disableMutationObserver: false,
      duration: 500, // values from 0 to 3000, with step 50ms
      easing: "ease",
    });
    AOS.refresh(true); 
});